<template>
    <div v-show="is_mounted">
        <div align="center">
            <h4 class="q-mb-sm">Definisci Pratica</h4>
            <h5 class="q-mt-sm q-mb-sm">Contratto {{IndiceProdotto+1}} di {{prodotti_selezionati.length}} - {{proposta_attiva.label}}</h5>
            <br>
            <hr>
        </div>

        <div class="row justify-center">

            <div class="col-12 col-md-5 justify-center">
                 <h5 >Allega PDF contratto</h5>
                <q-uploader
                    ref="FilesAllegati"
                    url="https://localhost:1000"
                    color="teal"
                    label="Trascina QUI i documenti di questo Contratto"
                    multiple
                    name="upload_contratto"
                    hide-upload-btn
                    :filter="checkFileType"
                    style="width: 100%; min-height: 350px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"
                    @rejected="onRejected()"
                    @removed="onRimuoviFileAllegati"
                    @added="onAggiungiFileAllegati"
                />
                <br>
            </div>



            <div class="col-12 col-md-5 q-px-md">
                <q-list separator>

                    <q-item>
                        <q-item-section>
                            <q-item-label>Premio</q-item-label>
                            <q-item-label caption><h5>{{getPremioCorrenteTotale()}} Euro</h5></q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Provvigioni</q-item-label>
                            <q-item-label caption><h5>{{getProvvigioniCorrentiTotali}} Euro</h5></q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-item-label>Compagnia</q-item-label>
                            <q-item-label caption>{{dati_contratto_originali.compagnia}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Fornitore</q-item-label>
                            <q-item-label caption>{{dati_contratto_originali.fornitore}}</q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-item-label>Tariffa</q-item-label>
                            <q-item-label caption>{{dati_contratto_originali.tariffa}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Frazionamento</q-item-label>
                            <q-item-label caption>{{dati_contratto_originali.frazionamento}}</q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-item-label>Decorrenza</q-item-label>
                            <q-item-label caption>{{dati_contratto_originali.decorrenza}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Scadenza</q-item-label>
                            <q-item-label caption>{{getScadenza()}}</q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item v-for="(garanzia, indexGaranzia) in proposta_attiva.parametri"
                        v-bind:key="indexGaranzia"
                        v-bind="garanzia">
                        <q-item-section>
                            <q-item-label>{{garanzia.label}}</q-item-label>
                            <q-item-label caption>{{ analizzaRisposta(garanzia) }}</q-item-label>
                        </q-item-section>
                    </q-item>

                </q-list>

            </div>

            <div class="col-12 col-md-5 q-px-md">

                <h5>Premi del Contratto</h5>
                <q-list>
                    <q-item>
                        <q-item-section>

                            <q-input
                                :error="isNotValidNumber(dati_contratti_modificati[indice_prodotto].premio)"
                                error-message="Il premio deve essere valorizzato con un valore numerico"
                                type="number"
                                v-model="dati_contratti_modificati[indice_prodotto].premio"
                                outlined
                                name="premio_lordo"
                                label="Premio lordo"
                                step="1">

                                <template v-slot:append>
                                    Euro
                                </template>

                            </q-input>
                        </q-item-section>

                        <q-item-section class="q-ml-sm">
                            <q-checkbox v-model="dati_contratti_modificati[indice_prodotto].somma_al_premio[0]" label="Somma al premio" />
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-input
                                type="number"
                                :error="isNotValidNumber(dati_contratti_modificati[indice_prodotto].provvigioni)"
                                error-message="Il premio deve essere valorizzato con un valore numerico"
                                v-model="dati_contratti_modificati[indice_prodotto].provvigioni"
                                outlined
                                name="provvigioni"
                                label="Provvigioni"
                                step="1"
                                readonly
                            >
                                <template v-slot:append>
                                    Euro
                                </template>

                            </q-input>
                        </q-item-section>
                        <q-item-section class="q-ml-sm">
                            <q-checkbox v-model="dati_contratti_modificati[indice_prodotto].somma_al_premio[1]" label="Somma al premio" />
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-input
                                type="number"
                                :error="isNotValidNumber(dati_contratti_modificati[indice_prodotto].diritti_terzi)"
                                error-message="Il premio deve essere valorizzato con un valore numerico"
                                v-model="dati_contratti_modificati[indice_prodotto].diritti_terzi"
                                outlined
                                name="diritti_terzi"
                                label="Diritti di terzi"
                                step="1"
                            >
                                <template v-slot:append>
                                    Euro
                                </template>

                            </q-input>
                        </q-item-section>
                        <q-item-section class="q-ml-sm">
                            <q-checkbox v-model="dati_contratti_modificati[indice_prodotto].somma_al_premio[2]" label="Somma al premio" />
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-input
                                type="number"
                                :error="isNotValidNumber(dati_contratti_modificati[indice_prodotto].diritti_fornitore)"
                                error-message="Il premio deve essere valorizzato con un valore numerico"
                                v-model="dati_contratti_modificati[indice_prodotto].diritti_fornitore"
                                outlined
                                name="diritti_fornitore"
                                label="Diritti Fornitore"
                                step="1"
                            >
                                <template v-slot:append>
                                    Euro
                                </template>

                            </q-input>
                        </q-item-section>
                        <q-item-section class="q-ml-sm">
                            <q-checkbox v-model="dati_contratti_modificati[indice_prodotto].somma_al_premio[3]" label="Somma al premio" />
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-input
                                type="number"
                                :error="isNotValidNumber(dati_contratti_modificati[indice_prodotto].diritti_emittente)"
                                error-message="Il premio deve essere valorizzato con un valore numerico"
                                v-model="dati_contratti_modificati[indice_prodotto].diritti_emittente"
                                outlined
                                name="diritti_emittente"
                                label="Diritti Emittente"
                                step="1"
                            >
                                <template v-slot:append>
                                    Euro
                                </template>

                            </q-input>
                        </q-item-section>
                        <q-item-section class="q-ml-sm">
                          <q-checkbox v-model="dati_contratti_modificati[indice_prodotto].somma_al_premio[4]" label="Somma al premio" />
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-input
                                type="number"
                                :error="isNotValidNumber(dati_contratti_modificati[indice_prodotto].diritti_abybroker)"
                                error-message="Il premio deve essere valorizzato con un valore numerico"
                                v-model="dati_contratti_modificati[indice_prodotto].diritti_abybroker"
                                outlined
                                name="diritti_aby"
                                label="Diritti Aby Broker"
                                step="1"
                            >
                                <template v-slot:append>
                                    Euro
                                </template>

                            </q-input>
                        </q-item-section>
                        <q-item-section class="q-ml-sm">
                            <q-checkbox v-model="dati_contratti_modificati[indice_prodotto].somma_al_premio[5]" label="Somma al premio" />
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-input
                                type="number"
                                :error="isNotValidNumber(dati_contratti_modificati[indice_prodotto].consulenza)"
                                error-message="Il premio deve essere valorizzato con un valore numerico"
                                v-model="dati_contratti_modificati[indice_prodotto].consulenza"
                                outlined
                                name="consulenza"
                                label="Consulenza"
                                step="1"
                            >
                                <template v-slot:append>
                                    Euro
                                </template>

                            </q-input>
                        </q-item-section>
                        <q-item-section class="q-ml-sm">
                            <q-checkbox v-model="dati_contratti_modificati[indice_prodotto].somma_al_premio[6]" label="Somma al premio" />
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-input
                                type="number"
                                :error="isNotValidNumber(dati_contratti_modificati[indice_prodotto].costo_servizio)"
                                error-message="Il premio deve essere valorizzato con un valore numerico"
                                v-model="dati_contratti_modificati[indice_prodotto].costo_servizio"
                                outlined
                                name="costo_servizio"
                                label="Costo del servizio"
                                step="1"
                            >
                                <template v-slot:append>
                                    Euro
                                </template>

                            </q-input>
                        </q-item-section>
                        <q-item-section class="q-ml-sm">
                            <q-checkbox v-model="dati_contratti_modificati[indice_prodotto].somma_al_premio[7]" label="Somma al premio" />
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-input
                                type="number"
                                :error="isNotValidNumber(dati_contratti_modificati[indice_prodotto].quota_consulenza_unita_operativa)"
                                error-message="Il premio deve essere valorizzato con un valore numerico"
                                v-model="dati_contratti_modificati[indice_prodotto].quota_consulenza_unita_operativa"
                                outlined
                                name="quota_consulenza_unita_operativa"
                                label="Quota consulenza Unità Operativa"
                                step="1"
                            >
                                <template v-slot:append>
                                    Euro
                                </template>

                            </q-input>
                        </q-item-section>
                        <q-item-section class="q-ml-sm">
                            <q-checkbox v-model="dati_contratti_modificati[indice_prodotto].somma_al_premio[8]" label="Somma al premio" />
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-input
                                type="number"
                                :error="isNotValidNumber(dati_contratti_modificati[indice_prodotto].quota_consulenza_aby)"
                                error-message="Il premio deve essere valorizzato con un valore numerico"
                                v-model="dati_contratti_modificati[indice_prodotto].quota_consulenza_aby"
                                outlined
                                name="quota_consulenza_aby"
                                label="Quota consulenza Aby Broker"
                                step="1"
                            >
                                <template v-slot:append>
                                    Euro
                                </template>

                            </q-input>
                        </q-item-section>
                        <q-item-section class="q-ml-sm">
                            <q-checkbox v-model="dati_contratti_modificati[indice_prodotto].somma_al_premio[9]" label="Somma al premio" />
                        </q-item-section>
                    </q-item>

                </q-list>

            </div>

            <div class="col-12 col-md-5 q-px-md">
                <h5>Dati del Contratto</h5>
                <q-input outlined
                    :error="isNotValid('required|date',dati_contratti_modificati[indice_prodotto].decorrenza)"
                    error-message="Data mancante o non valida"
                    v-model="dati_contratti_modificati[indice_prodotto].decorrenza"
                    mask="##/##/####"
                    name="decorrenza"
                    label="Data di decorrenza contrattuale">

                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                <q-date v-model="dati_contratti_modificati[indice_prodotto].decorrenza"
                                    mask="DD/MM/YYYY"
                                    >
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Close" flat />
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-icon>
                    </template>

                </q-input>

            <!--    <q-input outlined
                    :error="isNotValid('required|date',scadenza)"
                    error-message="Data mancante o non valida"
                    v-model="scadenza"
                    name="scadenza"
                    label="Data di scadenza rata"
                /> //-->

                <q-input outlined
                    :error="isNotValid('required|date',scadenza)"
                    error-message="Data mancante o non valida"
                    v-model="dati_contratti_modificati[indice_prodotto].scadenza"
                    mask="##/##/####"
                    name="scadenza"
                    label="Data di scadenza">

                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                <q-date v-model="dati_contratti_modificati[indice_prodotto].scadenza"
                                    mask="DD/MM/YYYY"
                                    >
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Close" flat />
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-icon>
                    </template>

                </q-input>

                <q-input outlined
                        :error="isNotValid('required',dati_contratti_modificati[indice_prodotto].numero_polizza)"
                        error-message="Il Numero di polizza della Compagnia deve essere inserito"
                        v-model="dati_contratti_modificati[indice_prodotto].numero_polizza"
                        label="Numero di Polizza Compagnia"
                />

                <template v-if="tariffa_esistente">
                    <q-select outlined
                        :error="isNotValid('required',dati_contratti_modificati[indice_prodotto].compagnia)"
                        error-message="La Compagnia deve essere valorizzata"
                        v-model="dati_contratti_modificati[indice_prodotto].compagnia"
                        :options="elenco_compagnie"
                        name="compagnia"
                        label="Compagnia" />

                    <q-select outlined
                        :error="isNotValid('required',dati_contratti_modificati[indice_prodotto].tariffa)"
                        error-message="La Tariffa deve essere valorizzata"
                        v-model="dati_contratti_modificati[indice_prodotto].tariffa"
                        :options="tariffe(dati_contratti_modificati[indice_prodotto].compagnia)"
                        name="tariffa"
                        label="Tariffa" />

                    <q-input outlined
                        :error="isNotValid('required',fornit)"
                        error-message="Il Fornitore deve essere valorizzato"
                        v-model="fornit"
                        label="Fornitore"
                        readonly />
                </template>

                <template v-if="!tariffa_esistente">
                    <q-select outlined
                        error-message="Il prodotto deve essere valorizzato"
                        v-model="prodotto_da_catalogo"
                        :options="elenco_prodotti"
                        label="Prodotto a catalogo"
                        name="prodotto_catalogo"
                        virtual-scroll-slice-size="0"
                        virtual-scroll-item-size="0"
                        @input="onSelectProdotto()"
                        style="margin: 4px;" />

                    <q-input outlined
                        v-model="dati_contratti_modificati[indice_prodotto].compagnia"
                        label="Compagnia"
                        readonly
                        style="margin: 4px;" />

                    <q-input outlined
                        v-model="dati_contratti_modificati[indice_prodotto].tariffa"
                        label="Tariffa"
                        readonly
                        style="margin: 4px;" />

                    <q-input outlined
                        v-model="dati_contratti_modificati[indice_prodotto].fornitore"
                        label="Fornitore"
                        readonly
                        style="margin: 4px;" />

                </template>

                <!-- <q-checkbox v-model="dati_contratti_modificati[indice_prodotto].tacito_rinnovo" label="TACITO RINNOVO" /> //-->
                <br>
                <q-select outlined
                    error-message="Devi definire se il contratto e` con Tacito Rinnovo oppure no"
                    v-model="dati_contratti_modificati[indice_prodotto].tacito_rinnovo"
                    :options="elenco_tacito_rinnovo"
                    label="TACITO RINNOVO"
                    name="tacito_rinnovo"
                    :error="isNotValid('required',dati_contratti_modificati[indice_prodotto].tacito_rinnovo)"
                    emit-value
                    map-options
                    virtual-scroll-slice-size="0"
                    virtual-scroll-item-size="0"
                    style="margin: 4px;"
                />

                <q-select outlined
                    error-message="Devi definire se la quietanza richiede il Simplo oppure no"
                    v-model="dati_contratti_modificati[indice_prodotto].abilita_simplo"
                    :options="elenco_tacito_rinnovo"
                    label="ABILITA RCHIESTA SIMPLO"
                    name="abilita_richiesta_simplo"
                    :error="isNotValid('required',dati_contratti_modificati[indice_prodotto].abilita_simplo)"
                    emit-value
                    map-options
                    virtual-scroll-slice-size="0"
                    virtual-scroll-item-size="0"
                    style="margin: 4px;"
                />

                <br>
                <h5>Premio totale Contratto</h5>
                <h3 id="premio_totale_contratto">{{calcolaPremioTotale}} Euro</h3>
            </div>

            <div class="col-12 col-md-12 justify-center">
                <br>
                <hr>
                <div class="col-12 col-md-10 row">

                    <div class="col-12 col-md-6" align="center">
                        <QQButton label="INDIETRO"
                            color="blue-grey"
                            icon="undo"
                            size="md"
                            icon_align="left"
                            @click.native="onIndietroClicked()"
                        />
                    </div>

                    <div class="col-12 col-md-6" align="center">
                        <QQButton :label="getLabelValue"
                            color="blue-grey"
                            :icon="getIconValue"
                            size="md"
                            icon_align="right"
                            :disabled="! pulsante_abilitato"
                            @click.native="onAvantiClicked()"
                        />
                    </div>
                </div>
            </div>



        </div>
        <br><br><br><br>

    </div>
</template>

<script>
    import { mapFields} from 'vuex-map-fields';
    import { mapState,mapActions } from 'vuex'
    import QQButton from "@/components/QQButton.vue";
    import api from "@/libs/api";
    import axios from 'axios';
    import validation from "@/libs/validation";
    import commonLib from "@/libs/commonLib.js";

    export default {
        name: "DefinisciPratica",
        components: {
            QQButton
        },
        data() {
            return {
// ----------------------------------------------------------------------------------------------------
                gestisci_le_fatture: false, // costante per attivare o disattivare da codice le fatture
// ----------------------------------------------------------------------------------------------------
                tariffa_esistente: false,
                is_mounted: false,
                prodotto_da_catalogo: "",
                proposta_attiva: {},
                elenco_compagnie: [],
                elenco_tariffe: [],
                elenco_prodotti: [],
                errorMessage: "",
                isReloadDocument: false,
                pulsante_abilitato: true,

                elenco_tacito_rinnovo: [
                    {label: "Si", value: true },
                    {label: "No", value: false }
                ],

                dati_contratto_originali: {
                    compagnia: "",
                    fornitore: "",
                    tariffa: "",
                    decorrenza: "",
                    scadenza: "",
                    frazionamento: "",
                    premio: 0,
                    provvigioni: 0,
                    diritti_terzi: 0,
                    diritti_fornitore: 0,
                    diritti_emittente: 0,
                    diritti_abybroker: 0,
                    consulenza: 0,
                    costo_servizio: 0,
                    quota_consulenza_unita_operativa: 0,
                    quota_consulenza_aby: 0
                },
                dati_contratti_modificati: [
                    {
                        compagnia: "",
                        numero_polizza: "",
                        fornitore: "",
                        tariffa: "",
                        decorrenza: "",
                        scadenza: "",
                        premio: 0,
                        provvigioni: 0,
                        frazionamento: "",
                        diritti_terzi: 0,
                        diritti_fornitore: 0,
                        diritti_emittente: 0,
                        diritti_abybroker: 0,
                        consulenza: 0,
                        costo_servizio: 0,
                        quota_consulenza_unita_operativa: 0,
                        quota_consulenza_aby: 0,
                        somma_al_premio: [true,false,true,true,false,true,true,true,false,false],
                        tacito_rinnovo: "",
                        elenco_files: [],
                        abilita_simplo: ""
                    },
                    {
                        compagnia: "",
                        numero_polizza: "",
                        fornitore: "",
                        tariffa: "",
                        decorrenza: "",
                        scadenza: "",
                        premio: 0,
                        provvigioni: 0,
                        frazionamento: "",
                        diritti_terzi: 0,
                        diritti_fornitore: 0,
                        diritti_emittente: 0,
                        diritti_abybroker: 0,
                        consulenza: 0,
                        costo_servizio: 0,
                        quota_consulenza_unita_operativa: 0,
                        quota_consulenza_aby: 0,
                        somma_al_premio: [true,false,true,true,false,true,true,true,false,false],
                        tacito_rinnovo: "",
                        elenco_files: [],
                        abilita_simplo: ""
                    },
                    {
                        compagnia: "",
                        numero_polizza: "",
                        fornitore: "",
                        tariffa: "",
                        decorrenza: "",
                        scadenza: "",
                        premio: 0,
                        provvigioni: 0,
                        frazionamento: "",
                        diritti_terzi: 0,
                        diritti_fornitore: 0,
                        diritti_emittente: 0,
                        diritti_abybroker: 0,
                        consulenza: 0,
                        costo_servizio: 0,
                        quota_consulenza_unita_operativa: 0,
                        quota_consulenza_aby: 0,
                        somma_al_premio: [true,false,true,true,false,true,true,true,false,false],
                        tacito_rinnovo: "",
                        elenco_files: [],
                        abilita_simplo: ""
                    },
                    {
                        compagnia: "",
                        numero_polizza: "",
                        fornitore: "",
                        tariffa: "",
                        decorrenza: "",
                        scadenza: "",
                        premio: 0,
                        provvigioni: 0,
                        frazionamento: "",
                        diritti_terzi: 0,
                        diritti_fornitore: 0,
                        diritti_emittente: 0,
                        diritti_abybroker: 0,
                        consulenza: 0,
                        costo_servizio: 0,
                        quota_consulenza_unita_operativa: 0,
                        quota_consulenza_aby: 0,
                        somma_al_premio: [true,false,true,true,false,true,true,true,false,false],
                        tacito_rinnovo: "",
                        elenco_files: [],
                        abilita_simplo: ""
                    },
                    {
                        compagnia: "",
                        numero_polizza: "",
                        fornitore: "",
                        tariffa: "",
                        decorrenza: "",
                        scadenza: "",
                        premio: 0,
                        provvigioni: 0,
                        frazionamento: "",
                        diritti_terzi: 0,
                        diritti_fornitore: 0,
                        diritti_emittente: 0,
                        diritti_abybroker: 0,
                        consulenza: 0,
                        costo_servizio: 0,
                        quota_consulenza_unita_operativa: 0,
                        quota_consulenza_aby: 0,
                        somma_al_premio: [true,false,true,true,false,true,true,true,false,false],
                        tacito_rinnovo: "",
                        elenco_files: [],
                        abilita_simplo: ""
                    },
                    {
                        compagnia: "",
                        numero_polizza: "",
                        fornitore: "",
                        tariffa: "",
                        decorrenza: "",
                        scadenza: "",
                        premio: 0,
                        provvigioni: 0,
                        frazionamento: "",
                        diritti_terzi: 0,
                        diritti_fornitore: 0,
                        diritti_emittente: 0,
                        diritti_abybroker: 0,
                        consulenza: 0,
                        costo_servizio: 0,
                        quota_consulenza_unita_operativa: 0,
                        quota_consulenza_aby: 0,
                        somma_al_premio: [true,false,true,true,false,true,true,true,false,false],
                        tacito_rinnovo: "",
                        elenco_files: [],
                        abilita_simplo: ""
                    },
                    {
                        compagnia: "",
                        numero_polizza: "",
                        fornitore: "",
                        tariffa: "",
                        decorrenza: "",
                        scadenza: "",
                        premio: 0,
                        provvigioni: 0,
                        frazionamento: "",
                        diritti_terzi: 0,
                        diritti_fornitore: 0,
                        diritti_emittente: 0,
                        diritti_abybroker: 0,
                        consulenza: 0,
                        costo_servizio: 0,
                        quota_consulenza_unita_operativa: 0,
                        quota_consulenza_aby: 0,
                        somma_al_premio: [true,false,true,true,false,true,true,true,false,false],
                        tacito_rinnovo: "",
                        elenco_files: [],
                        abilita_simplo: ""
                    },
                    {
                        compagnia: "",
                        numero_polizza: "",
                        fornitore: "",
                        tariffa: "",
                        decorrenza: "",
                        scadenza: "",
                        premio: 0,
                        provvigioni: 0,
                        frazionamento: "",
                        diritti_terzi: 0,
                        diritti_fornitore: 0,
                        diritti_emittente: 0,
                        diritti_abybroker: 0,
                        consulenza: 0,
                        costo_servizio: 0,
                        quota_consulenza_unita_operativa: 0,
                        quota_consulenza_aby: 0,
                        somma_al_premio: [true,false,true,true,false,true,true,true,false,false],
                        tacito_rinnovo: "",
                        elenco_files: [],
                        abilita_simplo: ""
                    },
                    {
                        compagnia: "",
                        numero_polizza: "",
                        fornitore: "",
                        tariffa: "",
                        decorrenza: "",
                        scadenza: "",
                        premio: 0,
                        provvigioni: 0,
                        frazionamento: "",
                        diritti_terzi: 0,
                        diritti_fornitore: 0,
                        diritti_emittente: 0,
                        diritti_abybroker: 0,
                        consulenza: 0,
                        costo_servizio: 0,
                        quota_consulenza_unita_operativa: 0,
                        quota_consulenza_aby: 0,
                        somma_al_premio: [true,false,true,true,false,true,true,true,false,false],
                        tacito_rinnovo: "",
                        elenco_files: [],
                        abilita_simplo: ""
                    }
                ],
                frazionamento: "",
                configurazione_fatture: {}
            }
        },
        computed: {
            ...mapFields("formPreventivo",{
                IndiceProdotto: "IndiceProdotto",
                IndiceMinimo: "IndiceMinimo",
                IndiceMassimo: "IndiceMassimo"
            }),
            ...mapFields('gestioneFatture', [
                "guid_pratica"
            ]),
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                prodotti: state => state.gestionePratiche.formPratica.preventivo.prodotti,
                // frazionamento: state => state.gestionePratiche.formPratica.preventivo.frazionamento,
                NumeroPratica: state => state.gestionePratiche.formPratica.preventivo.id,
                decorrenza_generale: state => state.gestionePratiche.formPratica.preventivo.decorrenza,
                pratica_id: state => state.gestionePratiche.formPratica.preventivo.id
            }),
            indice_prodotto() {
                var indice = this.IndiceProdotto;
                if (indice < 0) indice = 0;
                return indice;
            },
            getLabelValue() {
                var len = this.prodotti_selezionati.length;
                if (this.IndiceProdotto === (len-1)) return "REGISTRA";

                return "AVANTI";
            },
            decorrenza_originale() {
                var proposta = this.prodotti_selezionati[this.IndiceProdotto].proposte.filter(p => {
                    return p.is_checked;
                });

                if (commonLib.isEmpty(proposta[0].decorrenza)) return this.decorrenza_generale;

                return proposta[0].decorrenza;
            },
            getIconValue() {
                var len = this.prodotti_selezionati.length;
                if (this.IndiceProdotto === (len-1)) return "mdi-content-save-edit-outline";

                return "mdi-arrow-right-bold";
            },
            prodotti_selezionati() {
                //console.log("this.prodotti:",this.prodotti);
                return this.prodotti.filter(p => {
                    return p.is_checked;
                });
            },
            proposta_selezionata() {
                //console.log("prodotto_attivo:",this.prodotto_attivo);
                var proposte = this.prodotto_attivo.proposte;
                var proposta = proposte.filter(prop => {
                    return prop.is_checked;
                });

                return proposta[0];
            },
            prodotto_attivo() {
                var indice = this.IndiceProdotto;
                if (indice < 0) indice = 0;
                //console.log("indice:",indice);
                if (indice >= this.prodotti_selezionati.length) indice = this.prodotti_selezionati.length - 1;
                return this.prodotti_selezionati[indice];
            },
            scadenza() {
                return this.getScadenzaModificata();
            },
            fornit() {
                if (commonLib.isEmpty(this.dati_contratti_modificati[this.IndiceProdotto].tariffa)) return "";

                if (typeof this.dati_contratti_modificati[this.IndiceProdotto].tariffa === "string") return this.proposta_attiva.fornitore;

                return this.dati_contratti_modificati[this.IndiceProdotto].tariffa.fornitore;
            },
            getProvvigioni() {
                if (commonLib.isEmpty(this.dati_contratti_modificati[this.IndiceProdotto].provvigioni)) return "";

                return this.dati_contratti_modificati[this.IndiceProdotto].provvigioni;
            },
            getPremioRata() {
                return 10;
            },
            getProvvigioniCorrentiTotali() {
                var provv = this.dati_contratto_originali.provvigioni +
                            this.dati_contratto_originali.quota_consulenza_unita_operativa;

                            return provv.toFixed(2);
            },
        /*    getPremioCorrenteTotale() {

                console.log("dati contratto originali:",this.dati_contratto_originali);

                if (isNaN(this.dati_contratto_originali.consulenza)) this.dati_contratto_originali.consulenza = 0;

                var premio = parseFloat(this.dati_contratto_originali.premio) +
                            parseFloat(this.dati_contratto_originali.diritti_abybroker) +
                            parseFloat(this.dati_contratto_originali.diritti_terzi) +
                            parseFloat(this.dati_contratto_originali.consulenza);

                return premio.toFixed(2);
            }, */
            calcolaPremioTotale() {
                var premio = 0;
                if (commonLib.isEmpty(this.dati_contratti_modificati[this.IndiceProdotto])) {
                    //console.log("Se entra QUI allora non funziona");
                    return 0;
                }

                if (this.dati_contratti_modificati[this.IndiceProdotto].somma_al_premio[0])
                    premio += parseFloat(this.dati_contratti_modificati[this.IndiceProdotto].premio);
                if (this.dati_contratti_modificati[this.IndiceProdotto].somma_al_premio[1])
                    premio += parseFloat(this.dati_contratti_modificati[this.IndiceProdotto].provvigioni);
                if (this.dati_contratti_modificati[this.IndiceProdotto].somma_al_premio[2])
                    premio += parseFloat(this.dati_contratti_modificati[this.IndiceProdotto].diritti_terzi);
                if (this.dati_contratti_modificati[this.IndiceProdotto].somma_al_premio[3])
                    premio += parseFloat(this.dati_contratti_modificati[this.IndiceProdotto].diritti_fornitore);
                if (this.dati_contratti_modificati[this.IndiceProdotto].somma_al_premio[4])
                    premio += parseFloat(this.dati_contratti_modificati[this.IndiceProdotto].diritti_emittente);
                if (this.dati_contratti_modificati[this.IndiceProdotto].somma_al_premio[5])
                    premio += parseFloat(this.dati_contratti_modificati[this.IndiceProdotto].diritti_abybroker);
                if (this.dati_contratti_modificati[this.IndiceProdotto].somma_al_premio[6])
                    premio += parseFloat(this.dati_contratti_modificati[this.IndiceProdotto].consulenza);
                if (this.dati_contratti_modificati[this.IndiceProdotto].somma_al_premio[7])
                    premio += parseFloat(this.dati_contratti_modificati[this.IndiceProdotto].costo_servizio);
                if (this.dati_contratti_modificati[this.IndiceProdotto].somma_al_premio[8])
                    premio += parseFloat(this.dati_contratti_modificati[this.IndiceProdotto].quota_consulenza_unita_operativa);
                if (this.dati_contratti_modificati[this.IndiceProdotto].somma_al_premio[9])
                    premio += parseFloat(this.dati_contratti_modificati[this.IndiceProdotto].quota_consulenza_aby);

                premio = premio.toFixed(2);

                return premio;
            }
        },
        methods: {
            ...mapActions({
                fetchElencoCompagnie: "gestioneCatalogoProdotti/fetchElencoCompagnie",
                fetchElencoTariffe: "gestioneCatalogoProdotti/fetchElencoTariffe",
                fetchUploadUrlContratti: "gestioneDocumenti/fetchUploadUrlContratti",
                fetchDatiEconomiciPratica: "gestionePratiche/fetchDatiEconomiciPratica",
                fetchCatalogoProdottiByIDSettore: "gestioneCatalogoProdotti/fetchCatalogoProdottiByIDSettore",
                fetchElencoEmittenti: "gestioneCatalogoProdotti/fetchElencoEmittenti",
                fetchConfigurazioneFatture: "gestioneFatture/fetchConfigurazioneFatture"
            }),
            getPremioCorrenteTotale() {

                //console.log("dati contratto originali:",this.dati_contratto_originali);

                if (isNaN(this.dati_contratto_originali.consulenza)) this.dati_contratto_originali.consulenza = 0;

                var premio = parseFloat(this.dati_contratto_originali.premio) +
                            parseFloat(this.dati_contratto_originali.diritti_abybroker) +
                            parseFloat(this.dati_contratto_originali.diritti_terzi) +
                            parseFloat(this.dati_contratto_originali.consulenza);

                return premio.toFixed(2);
                },
            onSelectProdotto() {
                if (commonLib.isEmpty(this.dati_contratti_modificati[this.IndiceProdotto])) return;

                let codici = this.prodotto_da_catalogo.value.split(" / ");
                let codice_esteso = codici[1];
                if (codice_esteso.length === 4) {
                    let start = codice_esteso.substr(0,1);
                    let cod   = "0"+codice_esteso.substr(1,codice_esteso.length - 1);
                    codice_esteso = start + cod;
                    //console.log("Ci siamo:",codice_esteso);
                }

                var cerca_fornitore = this.elenco_fornitori.find(f => {
                    return f.label.includes(codice_esteso);
                });

                if (commonLib.isEmpty(cerca_fornitore)) {
                    alert("Fornitore non definito");
                    this.tariffa   = "";
                    this.fornitore = "";
                    return;
                }
                this.dati_contratti_modificati[this.IndiceProdotto].fornitore = cerca_fornitore.value;

                this.dati_contratti_modificati[this.IndiceProdotto].tariffa = (this.prodotti_selezionati[this.IndiceProdotto].id + this.prodotto_da_catalogo.nome_breve).toLowerCase();
                if (this.dati_contratti_modificati[this.IndiceProdotto].tariffa != "") {
                    this.dati_contratti_modificati[this.IndiceProdotto].tariffa =
                        this.dati_contratti_modificati[this.IndiceProdotto].tariffa.replaceAll("_","");
                }
                this.dati_contratti_modificati[this.IndiceProdotto].compagnia = this.prodotto_da_catalogo.nome_breve;
            },
            attivaPulsanti() {
                this.pulsante_abilitato = true;
            },
            disattivaPulsanti() {
                this.pulsante_abilitato = false;
                 setTimeout(()=>{
                    this.attivaPulsanti()
                }, 10000);
            },
            async onRicaricaFilesAllegati() {
                this.isReloadDocument = true;
                if (commonLib.isEmpty(this.dati_contratti_modificati[this.IndiceProdotto])) return;

                var len = this.dati_contratti_modificati[this.IndiceProdotto].elenco_files.length;
                for (var i=0;i<len;i++) {
                    var files = [];
                    files.push(this.dati_contratti_modificati[this.IndiceProdotto].elenco_files[i]);

                    await this.$refs.FilesAllegati.addFiles(files);
                }
                //console.log("onRicaricaFilesAllegati:",this.IndiceProdotto);

                this.isReloadDocument = false;
            },
            onCancellaFilesAllegati() {
                this.$refs.FilesAllegati.reset();
            },
            onRejected () {
                this.$q.dialog({
                    title: 'Attenzione',
                    message: 'Il tipo di documento allegato può essere solo nel formato ZIP,PDF,FDF,PNG,JPG,JPEG,GIF. Gli altri formati non sono accettati per questioni di sicurezza.'
                });
            },
            onRimuoviFileAllegati(files) {
                // Cerco nel mio array e rimuovo solo quello
                if (commonLib.isEmpty(this.dati_contratti_modificati[this.IndiceProdotto])) return ;

                for (var i=0;i<this.dati_contratti_modificati[this.IndiceProdotto].elenco_files.length;i++) {
                    if (this.dati_contratti_modificati[this.IndiceProdotto].elenco_files[i] === files[0]) {
                        this.dati_contratti_modificati[this.IndiceProdotto].elenco_files.splice(i,1);
                    }
                }
            },
            checkFileType(files) {
                let status = (files.filter(file => file.type === 'application/pdf').length === 1) ||
                             (files.filter(file => file.type === 'application/x-compressed').length === 1) ||
                             (files.filter(file => file.type === 'application/x-zip-compressed').length === 1) ||
                             (files.filter(file => file.type === 'image/png').length === 1) ||
                             (files.filter(file => file.type === 'image/jpeg').length === 1) ||
                             (files.filter(file => file.type === 'image/gif').length === 1) ||
                             (files.filter(file => file.type === 'application/vnd.fdf').length === 1);
                if (status) {
                    return files.filter(file => file.type === files[0].type);
                }

                return [];
            },
            analizzaRisposta(parametro) {
                let result = "";

                if (parametro.type === "checkbox") {
                    return parametro.is_checked ? "Si" : "No";
                }

                var value = parametro.value;
                var options = parametro.options;

                // Cerca la label tra le options in base alla risposta inserita
                let element = options.filter(elemento => {
                    return elemento.value === value;
                })
                if (element.length === 1) {
                    value = element[0].label;
                }

                if ((value !== null) && (Array.isArray(value))) {
                    value.forEach(valore => {
                        result += '"'+valore+'", ';
                    });
                    result = result.slice(0, -2);
                    return result.replace("_"," ");
                } else {
                    if (value !== null) {
                        return value.replace("_"," ");
                    } else
                        return "";
                }
            },
            getScadenza() {
                var anno = parseInt(this.dati_contratto_originali.decorrenza.split("/")[2]);
                var mese = parseInt(this.dati_contratto_originali.decorrenza.split("/")[1]);
                var giorno = this.dati_contratto_originali.decorrenza.split("/")[0];

                if (commonLib.isEmpty(this.dati_contratto_originali.frazionamento))
                    this.dati_contratto_originali.frazionamento = "";

                switch(this.dati_contratto_originali.frazionamento.toUpperCase()) {
                    case "ANNUALE":
                        return giorno.padStart(2,'0')+"/"+(mese+"").padStart(2,'0')+"/"+(anno+1);

                    case "SEMESTRALE":
                        mese += 6;
                        if (mese > 12) {
                            anno++;
                            mese = 12 - mese;
                        }
                        return giorno.padStart(2,'0')+"/"+(mese+"").padStart(2,'0')+"/"+(anno+"");
                }
            },
            getScadenzaContrattuale() {
                var anno = parseInt(this.dati_contratto_originali.decorrenza.split("/")[2]);
                var mese = parseInt(this.dati_contratto_originali.decorrenza.split("/")[1]);
                var giorno = this.dati_contratto_originali.decorrenza.split("/")[0];
                return giorno.padStart(2,'0')+"/"+(mese+"").padStart(2,'0')+"/"+(anno+1);
            },
            getScadenzaModificata() {
                var proposta = this.proposta_selezionata;
                return proposta.scadenza;
            },
            async InizializzaDatiOriginali(proposta_attiva) {

                if (commonLib.isEmpty(this.dati_contratti_modificati[this.IndiceProdotto])) return;

                var dati_economici_tutti = await this.fetchDatiEconomiciPratica(this.pratica_id);


                // Deve filtrare solo i dati economici associati alla tariffa
                var dati_economici = dati_economici_tutti.filter(dato_economico => {
                    return dato_economico.tariffa == this.proposta_attiva.tariffa;
                });

                // Cerca il prodotto corretto
                var prodotto_selezionato = this.prodotti.filter(prodotto => {
                    return prodotto.id === this.proposta_attiva.tariffa && prodotto.is_checked;
                });

                var consulenza =  prodotto_selezionato.consulenza;

                this.dati_contratto_originali.compagnia = proposta_attiva.compagnia;
                this.dati_contratto_originali.tariffa   = proposta_attiva.tariffa;
                this.dati_contratto_originali.fornitore = proposta_attiva.fornitore;
                this.dati_contratto_originali.premio    = proposta_attiva.premio;
                this.dati_contratto_originali.provvigioni = proposta_attiva.provvigioni;
                this.dati_contratto_originali.consulenza = consulenza; // this.prodotti[this.IndiceProdotto].consulenza;
                this.dati_contratto_originali.diritti_abybroker = proposta_attiva.diritti_aby;
                this.dati_contratto_originali.quota_consulenza_unita_operativa = 0;
                this.dati_contratto_originali.quota_consulenza_aby = 0;
                this.dati_contratto_originali.frazionamento = proposta_attiva.frazionamento;

                dati_economici.forEach(dato => {
                    switch(dato.tipo) {
                        case "PREMIO_LORDO_POLIZZA":
                            this.dati_contratto_originali.premio = dato.importo;
                        break;

                        case "PROVVIGIONI_POLIZZA":
                            this.dati_contratto_originali.provvigioni = dato.importo;
                        break;

                        case "DIRITTI_ABY":
                            this.dati_contratto_originali.diritti_abybroker = dato.importo;
                        break;

                        case "CONSULENZA":
                            this.dati_contratto_originali.consulenza = dato.importo;
                        break;

                        case "QUOTA_CONSULENZA_UNITA_OPERATIVA":
                            this.dati_contratto_originali.quota_consulenza_unita_operativa = dato.importo;
                        break;

                        case "QUOTA_CONSULENZA_ABY":
                            this.dati_contratto_originali.quota_consulenza_aby = dato.importo;
                        break;

                    /*    case "DIRITTI_TERZI":
                            this.dati_contratto_originali.diritti_terzi = dato.diritti_terzi;
                        break;

                        case "DIRITTI_FORNITORE":
                            this.dati_contratto_originali.diritti_fornitore = dato.diritti_fornitore;
                        break;

                        case "DIRITTI_EMITTENTE":
                            this.dati_contratto_originali.diritti_emittente = dato.diritti_emittente;
                        break;

                        case "COSTO_SERVIZIO":
                            this.dati_contratto_originali.costo_servizio = dato.costo_servizio;
                        break; */
                    }
                })

                this.dati_contratto_originali.diritti_terzi = 0;
                this.dati_contratto_originali.diritti_fornitore = 0;
                this.dati_contratto_originali.diritti_emittente = 0;
                this.dati_contratto_originali.costo_servizio = 0;


                //this.dati_contratto_originali.diritti_abybroker = proposta_attiva.diritti_abybroker;
                this.dati_contratto_originali.decorrenza  = this.decorrenza_originale;
                this.dati_contratto_originali.scadenza    = this.getScadenza;

                //console.log("proposta_attiva:",this.proposta_attiva);

                //if (this.dati_contratti_modificati[this.IndiceProdotto].compagnia === "")
                    this.dati_contratti_modificati[this.IndiceProdotto].compagnia = proposta_attiva.compagnia;
                    this.dati_contratti_modificati[this.IndiceProdotto].numero_polizza = proposta_attiva.numero_polizza_compagnia;
                //if (this.dati_contratti_modificati[this.IndiceProdotto].tariffa === "")
                    this.dati_contratti_modificati[this.IndiceProdotto].tariffa = proposta_attiva.tariffa;
                //if (this.dati_contratti_modificati[this.IndiceProdotto].fornitore === "")
                    this.dati_contratti_modificati[this.IndiceProdotto].fornitore = proposta_attiva.fornitore;
                //if (this.dati_contratti_modificati[this.IndiceProdotto].decorrenza === "")
                    this.dati_contratti_modificati[this.IndiceProdotto].decorrenza = this.decorrenza_originale;
                //if (this.dati_contratti_modificati[this.IndiceProdotto].scadenza === "")
                    //this.dati_contratti_modificati[this.IndiceProdotto].scadenza = this.getScadenzaModificata();
                    this.dati_contratti_modificati[this.IndiceProdotto].scadenza = this.getScadenzaContrattuale();

                dati_economici.forEach(dato => {
                    switch(dato.tipo) {
                        case "PREMIO_LORDO_POLIZZA":
                            if (this.dati_contratti_modificati[this.IndiceProdotto].premio === 0)
                                this.dati_contratti_modificati[this.IndiceProdotto].premio = dato.importo;
                        break;

                        case "PROVVIGIONI_POLIZZA":
                            if (this.dati_contratti_modificati[this.IndiceProdotto].provvigioni === 0)
                                this.dati_contratti_modificati[this.IndiceProdotto].provvigioni = dato.importo;
                        break;

                        case "DIRITTI_ABY":
                            if (this.dati_contratti_modificati[this.IndiceProdotto].diritti_abybroker === 0)
                                this.dati_contratti_modificati[this.IndiceProdotto].diritti_abybroker = dato.importo;
                        break;

                        case "CONSULENZA":
                            if (this.dati_contratti_modificati[this.IndiceProdotto].consulenza === 0)
                                this.dati_contratti_modificati[this.IndiceProdotto].consulenza = dato.importo;
                        break;

                        case "QUOTA_CONSULENZA_UNITA_OPERATIVA":
                            if (this.dati_contratti_modificati[this.IndiceProdotto].quota_consulenza_unita_operativa === 0)
                                this.dati_contratti_modificati[this.IndiceProdotto].quota_consulenza_unita_operativa = dato.importo;
                        break;

                        case "QUOTA_CONSULENZA_ABY":
                            if (this.dati_contratti_modificati[this.IndiceProdotto].quota_consulenza_aby === 0)
                                this.dati_contratti_modificati[this.IndiceProdotto].quota_consulenza_aby = dato.importo;
                        break;

                    /*    case "DIRITTI_TERZI":
                            if (this.dati_contratti_modificati[this.IndiceProdotto].diritti_terzi === 0)
                                this.dati_contratti_modificati[this.IndiceProdotto].diritti_terzi = dato.diritti_terzi;
                        break;

                        case "DIRITTI_FORNITORE":
                            if (this.dati_contratti_modificati[this.IndiceProdotto].diritti_fornitore === 0)
                                this.dati_contratti_modificati[this.IndiceProdotto].diritti_fornitore = dato.diritti_fornitore;
                        break;

                        case "DIRITTI_EMITTENTE":
                            if (this.dati_contratti_modificati[this.IndiceProdotto].diritti_emittente === 0)
                                this.dati_contratti_modificati[this.IndiceProdotto].diritti_emittente = dato.diritti_emittente;
                        break; */
                    }
                })

            },
            tariffe(compagnia) {
                if (commonLib.isEmpty(compagnia)) return [];

                if (typeof(compagnia) === "string") {
                    //console.log("Maremma cane!!");
                    if ((commonLib.isEmpty(this.elenco_tariffe)) || (this.elenco_tariffe.length === 0)) return [];

                    var elenco = this.elenco_tariffe.filter(tariffa => {
                        return tariffa.id_compagnia === (compagnia.toLowerCase()).replace(" ","_");
                    });

                    return elenco;
                }

                elenco = this.elenco_tariffe.filter(tariffa => {
                    return tariffa.id_compagnia === compagnia.value;
                });

               return elenco;
            },
            CaricaDatiContratto() {
                var prodotto = {};
                var prodotti_selezionati = this.prodotti.filter(p => {
                    return p.is_checked;
                });

                //console.log("prodotti selezionati:",prodotti_selezionati);

                if (prodotti_selezionati.length > this.IndiceProdotto) {
                    prodotto = prodotti_selezionati[this.IndiceProdotto];
                } else {
                    //this.IndiceProdotto = 0;
                    prodotto = prodotti_selezionati[0];
                }

                //console.log("prodotto:",prodotto);

                this.proposta_attiva = prodotto.proposte.filter(proposta => {
                    return proposta.is_checked;
                });
                this.proposta_attiva = this.proposta_attiva[0];

                // nel caso la decorrenza e la scadenza della proposta sono NULL li valorizza per
                // default prendendo la decorrenza generica del preventivo
                if (commonLib.isEmpty(this.proposta_attiva.decorrenza)) {
                    this.proposta_attiva.decorrenza = this.decorrenza_generale;
                    this.proposta_attiva.scadenza   = this.getScadenza();
                }


                this.InizializzaDatiOriginali(this.proposta_attiva);
            },
            async InviaDocumentiAlServer() {
                var IndiceContratto = 0;
                this.dati_contratti_modificati.forEach(dato => {

                    dato.elenco_files.forEach(async file => {

                        var url = await this.fetchUploadUrlContratti(
                            {
                                NumeroPratica: this.NumeroPratica,
                                Filename: file.name,
                                IndiceContratto: IndiceContratto
                            }
                        );

                        let formData = new FormData();
                        formData.append('files[0]', file);

                        await axios.put(
                            url,
                            formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }).then(function(){
                                //console.log('SUCCESS!!');
                            }).catch(function(){
                                //console.log('FAILURE!!');
                            });
                    });
                    IndiceContratto++;
                });

            },
            ControllaCampiSeCompilati() {
                var tutto_corretto = true;

                if (this.dati_contratti_modificati[this.IndiceProdotto].elenco_files.length === 0) tutto_corretto = false;
                if (this.dati_contratti_modificati[this.IndiceProdotto].decorrenza === "") tutto_corretto = false;
                if (this.dati_contratti_modificati[this.IndiceProdotto].scadenza === "") tutto_corretto = false;
                if (this.dati_contratti_modificati[this.IndiceProdotto].decorrenza === "") tutto_corretto = false;
                if (this.dati_contratti_modificati[this.IndiceProdotto].compagnia === "") tutto_corretto = false;
                if (this.dati_contratti_modificati[this.IndiceProdotto].numero_polizza === "") tutto_corretto = false;
                if (this.dati_contratti_modificati[this.IndiceProdotto].fornitore === "") tutto_corretto = false;
                if (this.dati_contratti_modificati[this.IndiceProdotto].tariffa === "") tutto_corretto = false;

                return tutto_corretto;
            },
            async onAvantiClicked() {
                //console.log("IndiceProdotto:",this.IndiceProdotto);
                //console.log("prodotti_selezionati.length:",this.prodotti_selezionati.length);

                if (this.IndiceProdotto < this.prodotti_selezionati.length-1) {

                    if (!this.ControllaCampiSeCompilati()) {
                        this.$q.dialog({
                            title: 'Attenzione',
                            message: 'Sono stati rilevati errori sui dati. Controlla di aver allegato il documento con il contratto e di aver compilato tutti i campi.',
                        });
                        return;
                    }

                    this.dati_contratti_modificati[this.IndiceProdotto].provvigioni = this.getProvvigioni;
                    this.dati_contratti_modificati[this.IndiceProdotto].fornitore   = this.fornit;

                    // Verifica se la compagnia e' una stringa o un oggetto
                    if (typeof(this.dati_contratti_modificati[this.IndiceProdotto].compagnia) !== "string") {
                        this.dati_contratti_modificati[this.IndiceProdotto].compagnia = this.dati_contratti_modificati[this.IndiceProdotto].compagnia.label;
                    }
                    if (typeof(this.dati_contratti_modificati[this.IndiceProdotto].tariffa) !== "string") {
                        this.dati_contratti_modificati[this.IndiceProdotto].tariffa = this.dati_contratti_modificati[this.IndiceProdotto].tariffa.value
                    }

                    this.IndiceProdotto++;
                    this.CaricaDatiContratto();
                    // cancella la lista dei files allegati per il contratto nuovo
                    this.onCancellaFilesAllegati();
                    // ricarica i documenti precedentemente caricati
                    this.onRicaricaFilesAllegati();
                    return;
                }

                if (!this.ControllaCampiSeCompilati()) {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Sono stati rilevati errori sui dati. Controlla di aver allegato il documento con il contratto e di aver compilato tutti i campi.',
                    });
                    return;
                }

                if (typeof(this.dati_contratti_modificati[this.prodotti_selezionati.length-1].compagnia) !== "string") {
                    this.dati_contratti_modificati[this.prodotti_selezionati.length-1].compagnia =
                        this.dati_contratti_modificati[this.prodotti_selezionati.length-1].compagnia.label
                }
                if (typeof(this.dati_contratti_modificati[this.prodotti_selezionati.length-1].tariffa) !== "string") {
                    this.dati_contratti_modificati[this.prodotti_selezionati.length-1].tariffa =
                        this.dati_contratti_modificati[this.prodotti_selezionati.length-1].tariffa.value;
                }

                // Se arriva QUI allora bisogna registrare, cambiare stato e uscire
                var fattura_abilitata = this.configurazione_fatture.fatture_enabled;
                var msg = "";

                if (this.gestisci_le_fatture) {
                    if (!fattura_abilitata) {
                        msg = "La fatturazione elettronica risulta disattivata per il seguente motivo: "+this.configurazione_fatture.note+". Sei sicuro di voler modificare questi contratti?";
                    } else {
                        msg = "Sei sicuro di voler modificare questi contratti?";
                    }
                } else {
                    msg = "Sei sicuro di voler modificare questi contratti?";
                }

                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "No, neanche un po`",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si, sono sicuro"
                    },
                    title: 'Attenzione',
                    message: msg,
                }).onOk(async () => {
                    // ================================================================
                    // TODO - Rimetti in piedo il codice sotto quando hai finito i test
                    // ================================================================
                    this.disattivaPulsanti();

                    // Aggiunsta i valori booleani sui dati dei contratti
                    var len = this.dati_contratti_modificati.length;
                    for (var i=0;i<len;i++) {
                        var contratto = this.dati_contratti_modificati[i];
                        if (typeof(contratto.abilita_simplo) === "string") contratto.abilita_simplo = false;
                        if (typeof(contratto.tacito_rinnovo) === "string") contratto.tacito_rinnovo = false;
                    }

                    var url = process.env.VUE_APP_API_URL + "pratiche/modificaDatiContratti";
                    var json = {
                        NumeroPratica: this.NumeroPratica,
                        dati_contratti: this.dati_contratti_modificati
                    };

                    //console.log("dati contratto modificati:",this.dati_contratti_modificati);

                    await api.postJSonToController(url,json);

                    // inizializza per il prossimo accesso alla pagina
                    this.IndiceProdotto = 0;
                    this.CaricaDatiContratto();

                    // invia tutti i documenti al server S3
                    await this.InviaDocumentiAlServer();


                    // MODIFICA PER LA GESTIONE DELLE FATTURE
                    // Verifica la configurazione. Se non abilitato a emettere la fattura, procede a vecchio
                    // altrimenti, salta alla pagina di gestione dei dati della fattura

                    if (this.gestisci_le_fatture) {
                        if (this.configurazione_fatture.fatture_enabled) {
                            this.$router.push({name : "Fatture.DatiClientePerFattura"});
                            return;
                        }
                    }

                    this.$router.push({name : "Pratiche.MenuGestionePratiche"});

                });


            },
            onIndietroClicked() {
                this.IndiceProdotto--;
                if (this.IndiceProdotto < 0) {
                    // Torna al menu delle pratiche
                    this.$router.push({name : "Pratiche.MenuGestionePratiche"});
                } else {
                    this.CaricaDatiContratto();
                    // cancella la lista dei files allegati per il contratto nuovo
                    this.onCancellaFilesAllegati();
                    // ricarica i documenti precedentemente caricati
                    this.onRicaricaFilesAllegati();
                }
            },
            async onAggiungiFileAllegati(files) {

                if (!this.isReloadDocument) {
                    await this.dati_contratti_modificati[this.IndiceProdotto].elenco_files.push(files[0]);
                }
            },
            setErrorMessage(msg) {
                this.errorMessage = msg;
            },
            isNotValid(RegoleValidazione,value) {
                let attributi = RegoleValidazione.split("|");
                let {Messaggio,esito} = validation.verificaRegole(attributi,value);
                // console.log("Messaggio validazione:",Messaggio,attributi,value);
                if (Messaggio !== "") console.log("Messaggio validazione:",Messaggio);
                return esito;
            },
            isNotValidNumber(value) {
                // verifica se il value e` effettivamente un numero
                if (isNaN(value) || (commonLib.isEmpty(value))) {
                    return true;
                }

                return false;
            },

        },
        async mounted()
        {
            //console.log("Sono in mounted");
            this.is_mounted = false;

            this.IndiceProdotto = 0;
            for (var i=0;i<this.prodotti_selezionati.length;i++) {
                await this.InizializzaDatiOriginali(i);
            }
            this.CaricaDatiContratto();

            console.log("dati_contratto_modificati:",this.dati_contratti_modificati);

            // Recupera la proposta attiva
            var proposta = this.prodotti_selezionati[this.IndiceProdotto].proposte.find(p => {
                return p.is_checked;
            });
            var tariffa = proposta.tariffa;

            this.elenco_compagnie = await this.fetchElencoCompagnie(false);
            this.elenco_tariffe   = await this.fetchElencoTariffe();

            //console.log("tariffa:",tariffa);

            // Verifica se la tariffa esiste nella lista delle tariffe locali ad Abynext
            var tariffa_esistente = this.elenco_tariffe.find(t => {
                return t.value === tariffa;
            });

            this.tariffa_esistente = !commonLib.isEmpty(tariffa_esistente);
            this.configurazione_fatture = await this.fetchConfigurazioneFatture(this.pratica_id);

            console.log("configurazione fatture:",this.configurazione_fatture);

            if (!this.tariffa_esistente) {
                this.elenco_prodotti = await this.fetchCatalogoProdottiByIDSettore({ prodotto_id: this.prodotti_selezionati[this.IndiceProdotto].id, ValueAsLabel: false});
                this.elenco_fornitori = await this.fetchElencoEmittenti();
            //    var fornitore = this.dati_contratto_originali.fornitore;


            //    var CodiceFornitoreEsteso = fornitore.split(" ")[0];
            //    var CodiceFornitore; //CodiceFornitoreEsteso;
            //    var start = CodiceFornitoreEsteso.substr(0,1);
            //    if (CodiceFornitoreEsteso[1] === '0') {
            //        CodiceFornitore = start + CodiceFornitoreEsteso.substr(2,CodiceFornitoreEsteso.length - 2);
            //    }

                //console.log("Compagnia:",this.dati_contratto_originali.compagnia);
                //console.log("Fornitore:",fornitore,CodiceFornitore,CodiceFornitoreEsteso);
                //console.log("Compagnia:",this.dati_contratto_originali.compagnia);
                //console.log("Fornitore:",fornitore,CodiceFornitore,CodiceFornitoreEsteso);
            }

            //console.log("elenco_tariffe:",this.elenco_tariffe,this.tariffa_esistente);

            this.is_mounted = true;
        }
    }
</script>
